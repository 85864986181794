<template>
  <div>
    <div
      class="alert alert-custom alert-light-danger fade show mb-10"
      role="alert"
      v-if="isPasswordMustChange"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-3x svg-icon-danger">
          <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
              <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
              <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
            </g>
          </svg>
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text font-weight-bold">
        Perbaharui Kata Sandi Anda Segera untuk menggunakan fitur pada aplikasi
        Grafindo Assesment !
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
        <div class="flex-row-auto" style="width: 100%" id="kt_profile_aside">
          <div class="card card-custom card-stretch">
            <div class="card-body pt-4">
              <div class="d-flex justify-content-end"></div>
              <div class="d-flex align-items-center">
                <div
                  class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
                >
                  <div class="symbol-label">
                    <img
                      class="symbol-label"
                      src="media/users/default.jpg"
                      alt=""
                    />
                  </div>
                  <i class="symbol-badge bg-success"></i>
                </div>
                <div>
                  <a
                    class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                    >{{ getFullName }}</a
                  >
                  <div class="text-muted">
                    {{ authUser.role == "student" ? "Pegawai" : "Admin" }}
                  </div>
                  <div class="mt-2">
                    <!-- <a
                  class="
                    btn btn-sm btn-primary
                    font-weight-bold
                    mr-2
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  >Chat</a
                >
                <a
                  class="
                    btn btn-sm btn-success
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  >Follow</a
                > -->
                  </div>
                </div>
              </div>
              <!--end::User-->
              <!--begin::Contact-->
              <div class="py-9">
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="font-weight-bold mr-2">username:</span>
                  <a class="text-muted text-hover-primary">{{
                    authUser.username
                  }}</a>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between"
                  v-if="authUser.vendor != 'admin'"
                >
                  <span class="font-weight-bold mr-2">Vendor :</span>
                  <span class="text-muted">{{ authUser.vendor.name }}</span>
                </div>
              </div>
              <!--end::Contact-->
              <!--begin::Nav-->
              <div
                class="navi navi-bold navi-hover navi-active navi-link-rounded"
                role="tablist"
              >
                <div class="navi-item mb-2">
                  <a
                    class="navi-link py-4 active"
                    @click="setActiveTab"
                    style="cursor: pointer"
                    data-tab="0"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <inline-svg src="media/svg/icons/Code/Compiling.svg" />
                      </span>
                    </span>
                    <span class="navi-text font-size-lg">Informasi Akun</span>
                  </a>
                </div>
                <div class="navi-item mb-2">
                  <a
                    class="navi-link py-4"
                    @click="setActiveTab"
                    style="cursor: pointer"
                    data-tab="1"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                  >
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <inline-svg
                          src="media/svg/icons/Communication/Shield-user.svg"
                        />
                      </span>
                    </span>
                    <span class="navi-text font-size-lg">Ubah kata Sandi</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--begin::Content-->
      <div class="col-lg-8 col-md-6 col-sm-12 mt-2">
        <div class="flex-row-fluid ml-lg-8">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab>
              <KTAccountInformation></KTAccountInformation>
            </b-tab>
            <b-tab>
              <KTChangePassword></KTChangePassword>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTDropdown2 from "@/view/content/dropdown/Dropdown2";
import KTAccountInformation from "@/view/pages/profile/profile-comp/AccountInformation";
import KTChangePassword from "@/view/pages/profile/profile-comp/ChangePassword";

export default {
  name: "custom-page",
  components: {
    KTAccountInformation,
    KTChangePassword,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profil" }]);
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
  },
  computed: {
    ...mapGetters(["authUser"]),
    isPasswordMustChange() {
      let user = this.$store.getters.authUser;
      return user.is_password_expired && user.role == "student";
    },
    getFullName() {
      return this.authUser.name;
    },
  },
};
</script>
